import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "upgrade-from-pre-100"
    }}>{`Upgrade from pre-1.0.0`}</h1>
    <p>{`We tried to keep `}<a parentName="p" {...{
        "href": "https://useauth.dev"
      }}><inlineCode parentName="a">{`useAuth`}</inlineCode></a>{` backwards compatible and ran into `}<a parentName="p" {...{
        "href": "https://github.com/Swizec/useAuth/issues/129"
      }}>{`fundamental issues with module imports`}</a>{`.`}</p>
    <p><strong parentName="p">{`Upgrading takes 5min or less!`}</strong>{` You can keep your existing config and most of the code! 2 small changes is all it takes.`}</p>
    <h2 {...{
      "id": "1-install-packages"
    }}>{`1. Install packages`}</h2>
    <p>{`Start by installing the latest package:`}</p>
    <pre><code parentName="pre" {...{}}>{`$ yarn add react-use-auth
`}</code></pre>
    <p>{`You'll need the `}<inlineCode parentName="p">{`auth0-js`}</inlineCode>{` client because it's now a peer dependency.`}</p>
    <pre><code parentName="pre" {...{}}>{`$ yarn add auth0-js
`}</code></pre>
    <p>{`If you prefer npm, that works too :)`}</p>
    <h2 {...{
      "id": "2-update-your-auth0_callback-page"
    }}>{`2. Update your auth0_callback page`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const { handleAuthentication } = useAuth();
useEffect(() => {
    handleAuthentication();
}, []);
`}</code></pre>
    <p>{`becomes`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const { handleAuthentication } = useAuth();
useEffect(() => {
    handleAuthentication();
}, [handleAuthentication]);
`}</code></pre>
    <p>{`This ensures `}<inlineCode parentName="p">{`handleAuthentication`}</inlineCode>{` runs after XState is initialized. You'll run into race conditions otherwise and users might get stuck.`}</p>
    <h2 {...{
      "id": "3-authprovider--authconfig"
    }}>{`3. AuthProvider 👉 AuthConfig`}</h2>
    <p><inlineCode parentName="p">{`<AuthProvider>`}</inlineCode>{` is now deprecated in favor of `}<inlineCode parentName="p">{`<AuthConfig>`}</inlineCode>{`. Using auth provider will print an error and authentication won't work. But we made sure your site doesn't otherwise break :)`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`export const wrapRootElement = ({ element }) => (
    <AuthProvider
        navigate={navigate}
        auth0_domain="useauth.auth0.com"
        auth0_client_id="GjWNFNOHq1ino7lQNJBwEywa1aYtbIzh"
    >
        {element}
    </AuthProvider>
);
`}</code></pre>
    <p>{`becomes`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { AuthConfig } from "react-use-auth";
import { Auth0 } from "react-use-auth/auth0";

export const wrapRootElement = ({ element }) => (
    <AuthConfig
        navigate={navigate}
        authProvider={Auth0}
        params={{
            domain: "useauth.auth0.com",
            clientID: "GjWNFNOHq1ino7lQNJBwEywa1aYtbIzh"
        }}
    >
        {element}
    </AuthConfig>
);
`}</code></pre>
    <p>{`For a full list of options go to `}<a parentName="p" {...{
        "href": "/docs/auth0"
      }}>{`Use with Auth0`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      